import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import Button from "../../Button/Button";
import CompanyInfoRules from "../../../rules/CompanyInfoRules";
import TextInput from "../../Inputs/TextInput";

const ValidationSchema = CompanyInfoRules.CompnayInfoSchema();

function CompanyInfo({
  Loading = false,
  errorMsg = "",
  InitialValues,
  onSubmission,
}) {
  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={onSubmission}
      validationSchema={ValidationSchema}
    >
      {({
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        values,
      }) => (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              topDivClass="form-elem"
              label="Company PAN"
              errorSpanClass="field-error-msg"
              value={values.PAN}
              maxLength="10"
              onChange={(e) =>
                setFieldValue("PAN", e.target.value.toUpperCase())
              }
              onBlur={(e) => setFieldTouched("PAN")}
              type="text"
              placeholder="Enter PAN"
              error={touched.PAN ? errors.PAN : ""}
            />
            <TextInput
              topDivClass="form-elem"
              label="Company GST"
              errorSpanClass="field-error-msg"
              value={values.GST}
              maxLength="15"
              onChange={(e) =>
                setFieldValue("GST", e.target.value.toUpperCase())
              }
              onBlur={(e) => setFieldTouched("GST")}
              type="text"
              placeholder="Enter GST"
              error={touched.GST ? errors.GST : ""}
            />
            <TextInput
              topDivClass="form-elem"
              label="Company CIN"
              errorSpanClass="field-error-msg"
              value={values.CIN}
              maxLength="21"
              onChange={(e) =>
                setFieldValue("CIN", e.target.value.toUpperCase())
              }
              onBlur={(e) => setFieldTouched("CIN")}
              type="text"
              placeholder="Enter CIN"
              error={touched.CIN ? errors.CIN : ""}
            />
            <small className="field-error-msg">{errorMsg}</small>
            <Button
              disabled={
                !values.PAN || !values.GST || !values.CIN || Loading === true
              }
              type="submit"
              style={{ borderRadius: "5px", marginRight: "0px" }}
            >
              Next
            </Button>
          </form>
        </>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(CompanyInfo);
