import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import SigninForm from "./form/SigninForm";
import ForgotPasswordForm from "./form/ForgotPasswordForm";
import SetPasswordForm from "./form/SetPasswordForm";
import * as actionTypes from "../../store/action";
import "./Employer.css";

function EmployerLogin(props) {
  const changeForm = (form, payload) => {
    switch (form) {
      case "forgotPassword": {
        setCurrentForm(
          <ForgotPasswordForm changeForm={changeForm} email={payload} />
        );
        break;
      }
      case "setPassword": {
        setCurrentForm(
          <SetPasswordForm changeForm={changeForm} passwordKey={payload} />
        );
        break;
      }
      case "signin": {
        setCurrentForm(<SigninForm changeForm={changeForm} />);
        break;
      }
      default:
        break;
    }
  };

  const [currentForm, setCurrentForm] = useState(
    <SigninForm changeForm={changeForm} />
  );

  return props.auth ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="e-login">
      <h1 className="login_head">Sign in</h1>
      <h3 className="login_sub-head">NinjaSalary</h3>

      {currentForm}

      <br />
      <p className="login_footer-text">
        New here? &nbsp;
        <span
          onClick={() => {
            props.switch("signup");
            props.history.push("/signup");
          }}
          className="login_special-text"
        >
          Sign up
        </span>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (fName, lName, cName, mobile, token) =>
      dispatch({
        type: actionTypes.LOGIN,
        firstName: fName,
        lastName: lName,
        companyName: cName,
        mobile: mobile,
        token: token,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerLogin);
