import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import * as actionTypes from "../../store/action";
import Navbar from "../Header/Navbar/Navbar";
import Button from "../Button/Button";
import "./Receipt.css";

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 1);
function Receipt(props) {
  // const paymentDate=useRef()
  // const paymentAmount = useRef();
  const paymentNo = useRef();

  const [paymentAmount, setPaymentAmount] = useState("");
  const [dateValue, setDateValue] = useState(new Date());
  const [monthValue, setMonthValue] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [monthCalendarOpen, setMonthCalendarOpen] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const swarlPopup = (text, error) => {
    Swal.fire({
      title: error ? "error" : "Done",
      text: text,
      icon: error ? "error" : "success",
      confirmButtonText: "Okay",
    });
  };

  const submission = (e) => {
    e.preventDefault();
    if (!paymentNo.current.value || !paymentAmount) {
      setErrorMsg("All fields are mendatory");
      return;
    }
    setErrorMsg("");
    setSubmitButtonDisabled(true);
    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/employer-payment/add-new-payment/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Token ${props.token}`,
        },
        body: JSON.stringify({
          month: monthValue.getMonth() + 1,
          year: monthValue.getFullYear(),
          total_payment_in_paisa: Number.parseFloat(paymentAmount) * 100,
          reference_number: paymentNo.current.value,
          payment_date: `${dateValue.getDate()}/${
            dateValue.getMonth() + 1
          }/${dateValue.getFullYear()}`,
        }),
      }
    )
      .then(async (res) => {
        setSubmitButtonDisabled(false);
        const data = await res.json();
        if (data.status) {
          swarlPopup(data.message);
          e.target.reset();
          setPaymentAmount("");
          return;
        }
        setErrorMsg(data.message);
      })
      .catch((err) => {
        setSubmitButtonDisabled(false);
        setErrorMsg("Error connecting to server");
      });
  };

  return props.auth ? (
    <div className="receipt">
      <div
        onClick={() => {
          setCalendarOpen(false);
          setMonthCalendarOpen(false);
        }}
        className={`receipt_backdrop ${
          calendarOpen || monthCalendarOpen ? "receipt_backdrop_active" : ""
        }`}
      />
      <Navbar
        logout={props.logoutAction}
        name={props.firstName}
        companyName={props.companyName}
      />

      <div className="receipt_box">
        <h2>Upload Receipt</h2>
        <form onSubmit={(e) => submission(e)}>
          <div className="receipt_form-elem">
            <label>Payment Date</label>
            <p
              style={{
                cursor: "pointer",
                margin: "0",
                minWidth: "250px",
                padding: "15px",
                boxShadow: "-1px 2px 5px rgb(0 0 0 / 10%)",
                borderRadius: "5px",
                fontSize: " 1rem",
                color: "#000",
              }}
              onClick={() => {
                setCalendarOpen(!calendarOpen);
              }}
            >
              {`${dateValue.getDate()} / ${
                dateValue.getMonth() + 1
              } / ${dateValue.getFullYear()}`}
            </p>
            <div
              className={`receipt_calendar ${
                calendarOpen ? "receipt_calendar_active" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Calendar
                defaultView="month"
                maxDate={new Date()}
                minDate={minDate}
                onClickDay={(value) => {
                  setDateValue(value);
                  setCalendarOpen(false);
                }}
                value={dateValue}
                view="month"
              />
            </div>
          </div>
          <div className="receipt_form-elem">
            <label>Payment Month</label>
            <p
              style={{
                cursor: "pointer",
                margin: "0",
                minWidth: "250px",
                padding: "15px",
                boxShadow: "-1px 2px 5px rgb(0 0 0 / 10%)",
                borderRadius: "5px",
                fontSize: "1rem",
                color: "#000",
              }}
              onClick={() => {
                setMonthCalendarOpen(!monthCalendarOpen);
              }}
            >
              {`${monthValue.getMonth() + 1} / ${monthValue.getFullYear()}`}
            </p>
            <div
              className={`receipt_calendar ${
                monthCalendarOpen ? "receipt_calendar_active" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Calendar
                defaultView="year"
                maxDate={new Date()}
                minDate={minDate}
                onClickMonth={(value) => {
                  setMonthValue(value);
                  setMonthCalendarOpen(false);
                }}
                value={monthValue}
                view="year"
              />
            </div>
          </div>

          <div className="receipt_form-elem">
            <label>Payment amount in rupee</label>
            <input
              type="tel"
              placeholder="Enter amount"
              value={paymentAmount}
              onChange={(e) => {
                const value = e.target.value;
                const char = value.slice(-1);
                if ((char >= "0" && char <= "9") || value === "") {
                  setPaymentAmount(value);
                }
              }}
            />
          </div>
          <div className="receipt_form-elem">
            <label>Reference number</label>
            <input ref={paymentNo} type="text" placeholder="Enter Value" />
          </div>
          <small
            style={{
              fontSize: "0.9rem",
              color: "#ff4500",
              fontWeight: "bolder",
              display: "block",
              maxWidth: "290px",
              textAlign: "center",
              margin: "auto",
            }}
          >
            {errorMsg}
          </small>
          <Button disabled={submitButtonDisabled} type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  ) : (
    <Redirect to="/signin" />
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    token: state.token,
    firstName: state.firstName,
    companyName: state.companyName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
