import React, { useEffect, useRef, useState } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Formik } from "formik";

import Button from "../../Button/Button";
import ColorPallete from "../../../config/ColorPallete";
import messages from "../../../config/Messages";
import TextInput from "../../Inputs/TextInput";
import SelectDropdown from "./../../Inputs/SelectDropdown";
import SignUpRules from "../../../rules/SignUpFormRules";
import PasswordInput from "../../Inputs/PasswordInput";

const ValidationSchema = SignUpRules.SignUpSchema();
const InitialValues = SignUpRules.InitialValues;
const CompnaySizes = SignUpRules.CompanySizes;
const CompanyRoles = SignUpRules.CompanyRoles;
const PasswordRegex = SignUpRules.PasswordRegex;
const EmailRegex = SignUpRules.EmailRegex;
const PasswordValidationErrors = SignUpRules.PasswordValidationErrors;
const CustomErrorsInitial = SignUpRules.CustomErrors;

function SignupForm(props) {
  const errorMessage = useRef();
  const [ButtonDisable, SetButtonDisabled] = useState(true);
  const [PasswordFocus, SetPasswordFocus] = useState(false);
  const [CustomError, SetCustomError] = useState(CustomErrorsInitial);
  const [Password, SetPassword] = useState("");
  const [PasswordErrors, SetPasswordErrors] = useState(
    PasswordValidationErrors
  );

  const checkEmailExistance = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/accounts/validate-email-address/`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            email_address: email,
          }),
        }
      );
      const data = response.json();
      return data;
    } catch (error) {
      return { status: false, message: "Please Enter email again." };
    }
  };

  const checkMobileExistance = async (mobile) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/accounts/validate-mobile-number/`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            mobile_number: mobile,
          }),
        }
      );
      const data = response.json();
      return data;
    } catch (error) {
      return { status: false, message: "Please Enter mobile again." };
    }
  };

  useEffect(() => {
    if (
      PasswordErrors.number === false &&
      PasswordErrors.special === false &&
      PasswordErrors.length === false &&
      PasswordErrors.length === false &&
      PasswordErrors.capital === false &&
      PasswordErrors.small === false
    ) {
      SetButtonDisabled(true);
      return;
    }
    SetButtonDisabled(false);
  }, [PasswordErrors]);

  useEffect(() => {
    const numberRegex = /^(?=.*\d)/;
    const lengthRegex = /^.{6,30}$/;
    const specialRegex = /^(?=.*[!@#$%^&*])/;
    const capitalRegex = /^(?=.*[A-Z])/;
    const smallRegex = /^(?=.*[a-z])/;

    const myErr = { ...PasswordErrors };
    myErr.number = numberRegex.test(Password);
    myErr.special = specialRegex.test(Password);
    myErr.length = lengthRegex.test(Password);
    myErr.capital = capitalRegex.test(Password);
    myErr.small = smallRegex.test(Password);
    SetPasswordErrors(myErr); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Password]);

  const onSubmission = (values) => {
    const data = {
      requester_name: values.Name,
      email_address: values.Email,
      mobile_number: Number.parseInt(values.Phone),
      company_name: values.CompanyName,
      company_size: CompnaySizes.indexOf(values.CompanySize) + 1,
      org_role: CompanyRoles.indexOf(values.Role) + 1,
      password: values.Password,
    };

    SetButtonDisabled(true);

    errorMessage.current.innerText = "";

    fetch(`${process.env.REACT_APP_SERVER}/ws/v1/company/company-signup/`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        SetButtonDisabled(false);

        let data = await res.json();
        if (data.created) {
          props.onSignup(data.message);
        } else {
          let msg = data.message;
          errorMessage.current.innerText = msg;
        }
      })
      .catch(() => {
        SetButtonDisabled(false);
        errorMessage.current.innerText = messages.ServerError;
      });
  };

  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={(values) => onSubmission(values)}
      validationSchema={ValidationSchema}
    >
      {({
        handleChange,
        setFieldTouched,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
        isValid,
        dirty,
      }) => (
        <>
          <form
            autoComplete="on"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              label="Name"
              placeholder="Enter your Name"
              onChange={handleChange("Name")}
              error={touched.Name ? errors.Name : ""}
              onBlur={() => setFieldTouched("Name")}
            />

            <TextInput
              label="Company Name"
              placeholder="Enter your Company"
              onChange={handleChange("CompanyName")}
              error={touched.CompanyName ? errors.CompanyName : ""}
              onBlur={() => setFieldTouched("CompanyName")}
            />

            <SelectDropdown
              label="Company Size"
              optionArray={CompnaySizes}
              error={touched.CompanySize ? errors.CompanySize : ""}
              onChange={handleChange("CompanySize")}
            />

            <SelectDropdown
              label="Your Role"
              optionArray={CompanyRoles}
              error={touched.Role ? errors.Role : ""}
              onChange={handleChange("Role")}
            />

            <TextInput
              label="Email"
              type="email"
              autoComplete="on"
              name="email"
              id="email"
              placeholder="abc@xyz.com"
              onChange={handleChange("Email")}
              onBlur={async (e) => {
                setFieldTouched("Email");
                const value = e.target.value.trim();
                if (EmailRegex.test(String(value).toLowerCase())) {
                  const check = await checkEmailExistance(value);
                  SetCustomError({
                    ...CustomError,
                    ...{ Email: check.status === true ? "" : check.message },
                  });
                }
              }}
              error={
                touched.Email
                  ? CustomError.Email.length
                    ? CustomError.Email
                    : errors.Email
                  : ""
              }
            />

            <TextInput
              label="Phone"
              type="tel"
              value={values.Phone}
              maxLength="10"
              autoComplete="on"
              name="phone"
              id="phone"
              pattern="[0-9]{10}"
              placeholder="123456789"
              onChange={(e) => {
                const value = e.target.value;
                const char = value.slice(-1);
                if ((char >= "0" && char <= "9") || value === "") {
                  setFieldValue("Phone", value);
                }
              }}
              onBlur={async (e) => {
                setFieldTouched("Phone");
                if (e.target.value.length === 10) {
                  const check = await checkMobileExistance(e.target.value);
                  SetCustomError({
                    ...CustomError,
                    ...{ Phone: check.status === true ? "" : check.message },
                  });
                }
              }}
              error={
                touched.Phone
                  ? CustomError.Phone.length
                    ? CustomError.Phone
                    : errors.Phone
                  : ""
              }
            />

            <PasswordInput
              error={
                touched.Password
                  ? CustomError.Password.length
                    ? CustomError.Password
                    : errors.Password
                  : ""
              }
              onChange={(e) => {
                const value = e.target.value.trim();
                SetPassword(value);
                setFieldValue("Password", value);
              }}
              onBlur={(e) => {
                setFieldTouched("Password");
                SetPasswordFocus(false);
                const check = PasswordRegex.test(e.target.value);
                SetCustomError({
                  ...CustomError,
                  ...{
                    Password: check === true ? "" : "Invalid Password",
                  },
                });
              }}
              onFocus={() => SetPasswordFocus(true)}
              value={values.Password}
            >
              <div
                className={`signup_pass-box ${
                  PasswordFocus ? "signup_pass-box_active" : " "
                }`}
              >
                <li>
                  <CheckBoxIcon
                    style={{
                      color: `${
                        PasswordErrors.capital
                          ? ColorPallete.green
                          : ColorPallete.gray
                      }`,
                    }}
                  />
                  <p>Capital letter [A-Z]</p>
                </li>
                <li>
                  <CheckBoxIcon
                    style={{
                      color: `${
                        PasswordErrors.small
                          ? ColorPallete.green
                          : ColorPallete.gray
                      }`,
                    }}
                  />
                  <p>Small letter [a-z]</p>
                </li>
                <li>
                  <CheckBoxIcon
                    style={{
                      color: `${
                        PasswordErrors.number
                          ? ColorPallete.green
                          : ColorPallete.gray
                      }`,
                    }}
                  />
                  <p>Number [0-9]</p>
                </li>
                <li>
                  <CheckBoxIcon
                    style={{
                      color: `${
                        PasswordErrors.special
                          ? ColorPallete.green
                          : ColorPallete.gray
                      }`,
                    }}
                  />
                  <p>Special Character [!@#$%^&*]</p>
                </li>
                <li>
                  <CheckBoxIcon
                    style={{
                      color: `${
                        PasswordErrors.length
                          ? ColorPallete.green
                          : ColorPallete.gray
                      }`,
                    }}
                  />
                  <p>Length [6-30]</p>
                </li>
              </div>
            </PasswordInput>

            <Button
              raised={!ButtonDisable}
              disabled={
                !(isValid && dirty) ||
                ButtonDisable ||
                CustomError.Email.length ||
                CustomError.Phone.length ||
                CustomError.Password.length
              }
              type="submit"
            >
              Sign up
            </Button>
            <small style={styles.MainError} ref={errorMessage}></small>
          </form>
        </>
      )}
    </Formik>
  );
}

export default SignupForm;

const styles = {
  MainError: {
    fontSize: "0.9rem",
    color: ColorPallete.red,
    fontWeight: "bolder",
    display: "block",
    transform: "translateY(-10px)",
    maxWidth: "290px",
    textAlign: "center",
    margin: "auto",
  },
};
