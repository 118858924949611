import React from "react";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import "./Main.css";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="dashboard_main">
      <div className="dashboard_main_box">
        <Link
          to="/directory"
          style={{ textDecoration: "none", textAlign: "center" }}
        >
          <div className="icon">
            <MenuBookIcon
              style={{ color: "#fec66c", fontSize: "5rem", fontWeight: "bold" }}
            />
          </div>
          <div className="title">Directory</div>
        </Link>
      </div>
      <Link
        to="/profile"
        style={{ textDecoration: "none", textAlign: "center" }}
      >
        <div className="dashboard_main_box">
          <div className="icon">
            <AccountCircleIcon
              style={{ color: "#7e5ea8", fontSize: "5rem", fontWeight: "bold" }}
            />
          </div>
          <div className="title">Profile</div>
        </div>
      </Link>
      <div className="dashboard_main_box">
        <Link
          to="/transaction-history"
          style={{ textDecoration: "none", textAlign: "center" }}
        >
          <div className="icon">
            <AccountBalanceIcon
              style={{ color: "#7e5ea8", fontSize: "5rem", fontWeight: "bold" }}
            />
          </div>
          <div className="title">Transaction History</div>
        </Link>
      </div>{" "}
      <Link
        to="/receipt"
        style={{ textDecoration: "none", textAlign: "center" }}
      >
        <div className="dashboard_main_box">
          <div className="icon">
            <ReceiptIcon
              style={{ color: "#fec66c", fontSize: "5rem", fontWeight: "bold" }}
            />
          </div>
          <div className="title">Upload Payment receipt</div>
        </div>
      </Link>
    </div>
  );
}

export default Main;
