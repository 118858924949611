import * as Yup from "yup";

const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InitialValues = {
  Email: "",
  Password: "",
};

const SignInSchema = () => {
  return Yup.object().shape({
    Email: Yup.string()
      .required("Email is required")
      .email("Please enter a valid Email."),
    Password: Yup.string().required("Password is required."),
  });
};

const Exports = {
  SignInSchema,
  InitialValues,
  EmailRegex,
};

export default Exports;
