function SelectDropdown({ label = "Default", optionArray = [], onChange }) {
  return (
    <div className="signup_form-elem">
      <label>{label}</label>
      <select onChange={onChange}>
        {optionArray.map((item, index) => (
          <option key={index}>{item}</option>
        ))}
      </select>
    </div>
  );
}

export default SelectDropdown;
