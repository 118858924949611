import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Grid, Divider } from "@material-ui/core";

import * as actionTypes from "../../store/action";
import Navbar from "../Header/Navbar/Navbar";
import Button from "../Button/Button";
import UpdatePassword from "./UpdatePassword/UpdatePassword";
// import UpdateProfile from "./UpdateProfile/UpdateProfile";
import Spinner from "../Spinner/Spinner";
import "./Profile.css";

function Profile(props) {
  const [updatePassOpen, setUpdatePassOpen] = useState(false);
  const [updateProfileOpen, setUpdateProfileOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [profileData, setProfileData] = useState({
    companyName: "",
    brandName: "",
    companySize: "",
    companyUrl: "",
    email: "",
    mobile: "",
    isVerified: "",
  });
  // const [editableData, setEditableData] = useState({});

  useEffect(() => {
    if (props.token) {
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/get-company-profile-data/`,
        {
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      ).then(async (res) => {
        if (res.status === 401) {
          props.logoutAction();
          return;
        }
        setDataLoaded(true);
        const response = await res.json();
        const data = response.data;
        if (response.status) {
          const myProfileData = { ...profileData };
          myProfileData.email = data.email.vale;
          myProfileData.companyName = data.legal_name.value;
          myProfileData.companySize =
            data.company_size.option[data.company_size.value];
          myProfileData.companyUrl = data.company_url.value;
          myProfileData.mobile = data.mobile_number.value;
          myProfileData.brandName = data.brand_name.value;
          myProfileData.isVerified = data.is_verified.value;
          setProfileData(myProfileData);

          const myEditableData = {};
          Object.keys(data).forEach(
            (key) => (myEditableData[key] = data[key].is_editable)
          );
          // setEditableData(myEditableData);
        }
      });
    }
  }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.auth ? (
    <div className="profile">
      <Navbar
        logout={props.logoutAction}
        name={props.firstName}
        companyName={props.companyName}
      />
      <div
        className={`profile_backdrop-box ${
          updatePassOpen || updateProfileOpen
            ? "profile_backdrop-box_active"
            : ""
        }`}
        onClick={() => {
          setUpdateProfileOpen(false);
          setUpdatePassOpen(false);
        }}
      >
        {updatePassOpen ? (
          <UpdatePassword onClose={() => setUpdatePassOpen(false)} />
        ) : updateProfileOpen ? (
          ""
        ) : (
          // <UpdateProfile
          //   data={editableData}
          //   onClose={() => setUpdateProfileOpen(false)}
          // />
          ""
        )}
      </div>

      {dataLoaded ? (
        <Grid
          container
          spacing={2}
          style={{ margin: "0", width: "100%" }}
          justify="center"
        >
          <Grid item xs={12} md={10} lg={9}>
            <h1>Profile</h1>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={10}
            lg={9}
            style={{ background: "#fff", padding: "15px" }}
          >
            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Name</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{props.firstName}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>

            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Brand Name</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{profileData.brandName}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>

            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Company Name</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{profileData.companyName}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>

            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Company Size</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{profileData.companySize}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>

            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Email</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{profileData.email}</p>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>

            <Grid item xs={5} lg={5}>
              <h5 style={{ margin: "10px 0" }}>Mobile</h5>
            </Grid>
            <Grid item xs={7} lg={7}>
              <p style={{ margin: "10px 0" }}>{profileData.mobile}</p>
            </Grid>
          </Grid>
          <Grid
            item
            xs={11}
            md={10}
            lg={9}
            style={{ display: "flex", justify: "center" }}
          >
            {/* <Button
              type="button"
              onClick={() => setUpdateProfileOpen(!updateProfileOpen)}
            >
              Update Profile
            </Button> */}
            <Button
              type="button"
              onClick={() => setUpdatePassOpen(!updatePassOpen)}
            >
              Update Password
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Spinner />
      )}
    </div>
  ) : (
    <Redirect to="/signin" />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    token: state.token,
    firstName: state.firstName,
    lastName: state.lastName,
    companyName: state.companyName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
