import * as Yup from "yup";

const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const CompanySizes = ["1-10", "11-20", "21-50", "51-100", "101-500", "500+"];

const PasswordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;

const CompanyRoles = [
  "HR Team",
  "Finance Team",
  "Management Team",
  "HR consulting firm",
  "Payroll consulting firm",
  "Others",
];

const PasswordValidationErrors = {
  length: false,
  special: false,
  capital: false,
  small: false,
  number: false,
};

const CustomErrors = {
  Email: "",
  Phone: "",
  Password: "",
};

const InitialValues = {
  Name: "",
  CompanyName: "",
  CompanySize: CompanySizes[0],
  Role: CompanyRoles[0],
  Email: "",
  Phone: "",
  Password: "",
};

const SignUpSchema = () => {
  return Yup.object().shape({
    Name: Yup.string().required("Name is required"),
    CompanyName: Yup.string().required("Company Name is required"),
    CompanySize: Yup.string().required("Company Size is required"),
    Role: Yup.string().required("Your Role is required"),
    Email: Yup.string().required("Email is required").email("Invalid Email"),
    Phone: Yup.string().max(10).required("Phone is required"),
    Password: Yup.string().required("Password is required").min(6).max(30),
  });
};

const Exports = {
  SignUpSchema,
  InitialValues,
  CompanySizes,
  CompanyRoles,
  EmailRegex,
  PasswordRegex,
  PasswordValidationErrors,
  CustomErrors,
};

export default Exports;
