import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import * as actionTypes from "../../../store/action";
import DataTable from "react-data-table-component";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
// import ExportIcon from "@material-ui/icons/Publish";
import ImportIcon from "@material-ui/icons/GetApp";
import EmployeesIcon from "@material-ui/icons/SupervisedUserCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CancelIcon from "@material-ui/icons/Cancel";
import { IconButton } from "@material-ui/core";

import Button from "../../Button/Button";
import Spinner from "../../Spinner/Spinner";
import "./Table.css";

let searchTimer;

function Table(props) {
  const searchInput = useRef();
  const uploadFileInput = useRef();

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false);
  const [importBoxOpen, setImportBoxOpen] = useState(false);
  const [uploadBoxOpen, setUploadBoxOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [visibleColumnsCount, setVisibleColumnsCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(props.dataLoaded);

  const [uploadFileName, setUploadFileName] = useState(" ");
  const [uploadErrorMsg, setUploadErrorMsg] = useState(" ");
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
  const [uploadButtonText, setUploadButtonText] = useState("Submit");
  const [file, setFile] = useState();

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "#EEEEEE",
        borderRight: "1px solid #ccc",
        color: "#000",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRight: "1px solid #ddd",
        width: "150px",
      },
    },
  };

  const populateVisibleColumns = (totalColumns) => {
    const result = totalColumns.filter((item) => item.visible);
    setVisibleColumnsCount(result.length);
    setVisibleColumns(result);
  };

  const columnChangedHandler = (event, index) => {
    const myColumns = [...columns];
    myColumns[index].visible = event.target.checked;
    setColumns(myColumns);
    populateVisibleColumns(myColumns);
  };

  const debounceSearchData = (search) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      searchData(search);
    }, 600);
  };

  const searchData = (search) => {
    if (!search) {
      setVisibleData(data);
      return;
    }
    const value = search.trim();

    searchInput.current.placeholder = "Searching";

    const result = data.filter((item) => {
      if (
        item.user_name.search(new RegExp(value, "i")) >= 0 ||
        item.employee_id === value
      )
        return true;
      else return false;
    });

    setVisibleData(result);
    searchInput.current.placeholder = "Search";
  };

  const downloadSample = () => {
    if (props.token) {
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/bulk-upload-user-template/`,
        {
          method: "GET",
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `NinjaSalary_employee_upload_sample.xlsx`
          );

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        })
        .catch((err) => {
          console.log(
            "Can't connect to server for Downloading sample file",
            err
          );
        });
    }
  };

  const uploadInputChanged = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    const fileName = file.name;
    setUploadFileName(fileName);
    const ext = fileName.substr(fileName.lastIndexOf(".") + 1);

    if (ext !== "xls" && ext !== "xlsx") {
      setUploadErrorMsg("File should only be .xls or .xlsx");

      return;
    } else if (fileSize > 2.5) {
      setUploadErrorMsg("File size should be smaller than 2.5 MB");

      return;
    } else {
      setFile(file);
      setUploadErrorMsg("");
      setUploadButtonDisabled(false);
    }
  };

  const uploadFile = () => {
    if (!file) {
      setUploadErrorMsg("File not Present");
      return;
    }
    const form = new FormData();
    form.append("csv_file", file);

    setUploadButtonDisabled(true);
    setUploadButtonText("Uploading...");

    fetch(`${process.env.REACT_APP_SERVER}/ws/v1/company/bulk-upload-user/`, {
      method: "POST",
      headers: {
        authorization: `Token ${props.token}`,
      },
      body: form,
    })
      .then(async (res) => {
        setUploadButtonDisabled(false);
        setUploadButtonText("Submit");
        if (res.status === 401) {
          props.logoutAction();
          return;
        }

        const data = await res.json();
        if (data.status) {
          setUploadErrorMsg(data.message);
          setUploadBoxOpen(false);
          swarlPopup("Uploaded Successfully");
          setDataLoaded(false);
          props.refreshAll();
          setTimeout(() => setDataLoaded(true), 800);
        } else {
          setUploadErrorMsg(data.message);
        }
      })
      .catch((err) => {
        setUploadButtonDisabled(false);
        setUploadButtonText("Submit");
        console.log("Error in sending file", err);
      });
  };

  const swarlPopup = (text, error) => {
    Swal.fire({
      title: error ? "error" : "Done",
      text: text,
      icon: "success",
      confirmButtonText: "Okay",
    });
  };

  const inviteEmployees = () => {
    const uu = [];
    selectedRows.forEach((item) => uu.push(item.uu));
    if (uu.length === 0) return;
    fetch(`${process.env.REACT_APP_SERVER}/ws/v1/company/invite-user/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Token ${props.token}`,
      },
      body: JSON.stringify({
        all_user_uu: uu,
      }),
    })
      .then(async (res) => {
        if (res.status === 401) {
          props.logoutAction();
          return;
        }
        const data = await res.json();
        if (data.status) {
          swarlPopup(data.message);
        } else {
          swarlPopup("Error sending invites.", true);
        }
      })
      .catch((err) =>
        console.log("Error contacting server to invite employees", err)
      );
  };
  const onRollout = () => {
    if (props.token) {
      setUploadButtonDisabled(true);
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/roll-out-all-user/`,
        {
          method: "POST",
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      )
        .then(async (res) => {
          setUploadButtonDisabled(false);
          const data = await res.json();
          if (data.status) {
            swarlPopup(data.message);
          }
        })
        .catch((err) => {
          setUploadButtonDisabled(false);
          console.log("can't react server for Roll out");
        });
    } else {
      console.log("no token");
    }
  };

  useEffect(() => {
    setDataLoaded(props.dataLoaded);
  }, [props.dataLoaded]);

  useEffect(() => {
    const data = props.tableData;
    const columns = [
      {
        name: "Id",
        selector: "employee_id",
        visible: true,
        checked: true,
        wrap: true,
        width: "100px",
      },
      {
        name: "Name",
        selector: "user_name",
        visible: true,
        checked: true,
        wrap: true,
      },
      {
        name: "Email",
        selector: "email",
        visible: true,
        wrap: true,
      },

      {
        name: "Mobile",
        selector: "mobile_number",
        visible: true,
        wrap: true,
      },
      {
        name: "User Role",
        selector: "user_role",
        visible: false,
        wrap: true,
      },
      {
        name: "Employee Type",
        selector: "employee_type",
        visible: true,
        wrap: true,
      },
      {
        name: "Joining Date to N.S",
        selector: "date_joined",
        visible: false,
        wrap: true,
        width: "200px",
      },
      {
        name: "Company Joining Date",
        selector: "date_of_join",
        visible: false,
        wrap: true,
        width: "200px",
      },
      {
        name: "Self Onboarded",
        selector: "is_self_onboard",
        visible: false,
        wrap: true,
      },
      {
        name: "Mobile verified",
        selector: "is_mobile_verified",
        visible: false,
        wrap: true,
      },
    ];
    setData(data);
    setVisibleData(data);
    setColumns(columns);
    populateVisibleColumns(columns);
  }, [props.tableData]);

  return (
    <div className="directory_table">
      <div
        className={`directory_upload_backdrop ${
          uploadBoxOpen ? "directory_upload_backdrop_active" : ""
        }`}
        onClick={() => setUploadBoxOpen(false)}
      >
        <form
          className="directory_upload"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onSubmit={(e) => {
            e.preventDefault();
            uploadFile();
            e.target.reset();
            setUploadFileName(" ");
          }}
        >
          <IconButton
            onClick={() => setUploadBoxOpen(false)}
            style={{ marginLeft: "auto", padding: "5px" }}
          >
            <CancelIcon />
          </IconButton>
          <h3>Select File</h3>

          <div
            onClick={() => uploadFileInput.current.click()}
            style={{
              borderRadius: "10px",
              border: "2px solid var(--primary-color)",
              background: "#fff",
              color: "#000",
              fontWeight: "500",
              padding: "10px 40px",
              cursor: "default",
            }}
          >
            Select
          </div>
          <p>{uploadFileName}</p>
          <input
            ref={uploadFileInput}
            type="file"
            accept=".xls,.xlsx"
            onChange={(e) => uploadInputChanged(e)}
            style={{ display: "none" }}
          />
          <small
            style={{
              fontSize: "0.9rem",
              color: "#ff4500",
              fontWeight: "bolder",
              display: "block",
              maxWidth: "290px",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            {uploadErrorMsg}
          </small>
          <Button
            style={{ borderRadius: "10px", margin: "20px" }}
            type="submit"
            disabled={uploadButtonDisabled}
          >
            {uploadButtonText}
          </Button>
        </form>
      </div>
      {ReactDOM.createPortal(
        <div
          className={`directory_table_backdrop ${
            columnSelectorOpen || importBoxOpen
              ? "directory_table_backdrop_active"
              : ""
          }`}
          onClick={() => {
            setImportBoxOpen(false);
            setColumnSelectorOpen(false);
          }}
        />,
        document.querySelector(".app-backdrops")
      )}

      <div className="directory_table_topbar">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {/* <div className="directory_table_topbar_item">
            <Checkbox style={{ color: "var(--primary-color)" }} />
            <p>Inactive Employees</p>
          </div> */}
          <div className="directory_table_topbar_item">
            <EmployeesIcon />
            <p>Employees onboarded : </p>
            <span style={{ marginBottom: "0" }}>
              {" "}
              {props.onboardedEmployees}
            </span>
          </div>
          <div
            className="directory_table_topbar_item custom-column"
            style={{ cursor: "pointer" }}
            onClick={() => setColumnSelectorOpen(!columnSelectorOpen)}
          >
            <p>
              Columns{" "}
              <span
                style={{
                  fontWeight: "bolder",
                  fontSize: "1rem",
                  paddingLeft: "0 2px",
                }}
              >
                {visibleColumnsCount}
              </span>
            </p>
            <ArrowDropDownIcon />

            <div
              className={`custom-column-box ${
                columnSelectorOpen ? "custom-column-box_active" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {columns.map((item, i) => (
                <div key={i}>
                  {item.checked ? (
                    <Checkbox checked disabled style={{ color: "gray" }} />
                  ) : (
                    <Checkbox
                      checked={item.visible}
                      style={{ color: "var(--primary-color)" }}
                      onChange={(event) => columnChangedHandler(event, i)}
                    />
                  )}
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {props.showRollOut ? (
          <Button
            onClick={onRollout}
            type="button"
            style={{ marginRight: "0" }}
          >
            Roll out
          </Button>
        ) : (
          <Button
            type="button"
            disabled={selectedRowCount === 0}
            onClick={inviteEmployees}
            style={{ marginRight: "0" }}
          >
            Invite Employee
          </Button>
        )}
      </div>
      <hr />
      <div className="directory_table_navbar">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="select_count">{selectedRowCount}</div>
          <div className="search">
            <input
              type="text"
              onChange={(e) => {
                debounceSearchData(e.target.value);
              }}
              onKeyUp={(e) =>
                e.keyCode === 13 ? searchData(e.target.value) : ""
              }
              ref={searchInput}
              placeholder="Search"
            />
            <SearchIcon onClick={searchData} style={{ cursor: "pointer" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            className="table_navbar_item"
            onClick={() => setImportBoxOpen(!importBoxOpen)}
          >
            <ImportIcon
              style={{
                backgroundColor: "green",
                color: "#fff",
                borderRadius: "50%",
                fontSize: "2rem",
                padding: "5px",
              }}
            />
            <p>Import</p>
            <div
              className={`import-box ${
                importBoxOpen ? "import-box_active" : ""
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                type="button"
                onClick={() => {
                  setImportBoxOpen(false);
                  setUploadBoxOpen(true);
                }}
              >
                Import File
              </button>

              <p onClick={downloadSample}>Download Sample Format</p>
            </div>
          </div>
          {/* <div className="table_navbar_item">
            <ExportIcon
              style={{
                backgroundColor: "red",
                color: "#fff",
                borderRadius: "50%",
                fontSize: "2rem",
                padding: "5px",
              }}
            />
            <p>Export</p>
          </div> */}
        </div>
      </div>

      {dataLoaded ? (
        <DataTable
          columns={visibleColumns}
          customStyles={customStyles}
          style={{
            border: "1px solid lightgray",
          }}
          data={visibleData}
          highlightOnHover
          striped
          responsive
          noHeader
          selectableRows
          // onRowClicked={(e) => {
          //   console.log(e);
          // }}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            style: { color: "var(--primary-color)" },
          }}
          selectableRowsHighlight
          onSelectedRowsChange={(obj) => {
            setSelectedRows(obj.selectedRows);
            setSelectedRowCount(obj.selectedCount);
          }}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 60]}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
