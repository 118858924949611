import React from "react";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

function SignedupForm(props) {
  return (
    <div style={{ background: "#fff", width: "100%", textAlign: "center" }}>
      <CheckIcon style={{ color: "var(--primary-color)", fontSize: "6rem" }} />
      <h3>Successfully Signed Up</h3>
      <p>{props.message}</p>
      <br />
    </div>
  );
}

export default SignedupForm;
