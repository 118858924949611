import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actionTypes from "../../store/action";

import Navbar from "../Header/Navbar/Navbar";
import "./Dashboard.css";
import SidebarLeft from "./Sidebar/SidebarLeft";
import Main from "./Main/Main";
import SidebarRight from "./Sidebar/SidebarRight";

function Dashboard(props) {
  const [employeesOnboarded, setEmployeesOnboarded] = useState();
  const [totalEmployees, setTotalEmployees] = useState();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [moneyWithdrawl, setMoneyWithdrawl] = useState(0);
  const [noOfEmployees, setNoOfEmployees] = useState(0);
  const [showRollOut, setShowRollOut] = useState(false);

  useEffect(() => {
    if (props.token) {
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/company-dashboard/`,
        {
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      )
        .then(async (res) => {
          if (res.status === 401) {
            props.logoutAction();
            return;
          }
          const response = await res.json();
          const data = response.data;
          setShowRollOut(data.show_roll_out);
          setTotalEmployees(data.employee_stats.all_added_user_count);
          setEmployeesOnboarded(
            data.employee_stats.all_self_onboarded_user_count
          );
        })
        .catch((err) => {
          console.log("error in contacting server");
        });
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/user-disbursement/get-company-dashboard-disbursement-stats/`,
        {
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      ).then(async (res) => {
        if (res.status === 401) {
          props.logoutAction();
          return;
        }
        const data = await res.json();
        if (data.status) {
          setTotalTransactions(data.data.number_of_transaction);
          setMoneyWithdrawl(data.data.total_disbursed_amount);
          setNoOfEmployees(data.data.number_of_employees);
        }
      });
    } else {
      // console.log("Token not present");
    }
  }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.auth ? (
    <div className="dashboard">
      <Navbar
        logout={props.logoutAction}
        name={props.firstName}
        companyName={props.companyName}
      />
      <div className="dashboard_body">
        <SidebarLeft
          totalTransactions={totalTransactions}
          noOfEmployees={noOfEmployees}
          moneyWithdrawl={moneyWithdrawl}
        />
        <Main />
        <SidebarRight
          onboarded={employeesOnboarded}
          notActivated={totalEmployees - employeesOnboarded}
          showRollOut={showRollOut}
        />
      </div>
    </div>
  ) : (
    <Redirect to="/signin" />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firstName: state.firstName,
    lastName: state.lastName,
    companyName: state.companyName,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
