import * as actionTypes from "./action";

const initialState = {
  isLoaded: false,
  mobileView: false,
  auth: false,
  firstName: "",
  lastName: "",
  mobile: "",
  companyName: "",
  token: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_VIEW: {
      const myState = { ...state };
      myState.mobileView = action.mobileView;
      return myState;
    }

    case actionTypes.LOADED: {
      const myState = { ...state };
      myState.isLoaded = true;
      return myState;
    }

    case actionTypes.LOGIN: {
      const myState = { ...state };
      myState.auth = true;
      myState.firstName = action.firstName;
      myState.lastName = action.lastName;
      myState.companyName = action.companyName;
      myState.mobile = action.mobile;
      myState.token = action.token;
      return myState;
    }

    case actionTypes.LOGOUT: {
      const myState = { ...state };
      myState.auth = false;
      myState.firstName = "";
      myState.lastName = "";
      myState.mobile = "";
      myState.token = "";
      localStorage.removeItem("ns-token");
      fetch(`${process.env.REACT_APP_SERVER}/ws/v1/accounts/logout/`, {
        headers: {
          authorization: `Token ${state.token}`,
        },
      });

      // window.location.replace(window.location.origin + "/");
      return myState;
    }

    default:
      return state;
  }
};

export default reducer;
