import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import Button from "../../Button/Button";

function CompanyProof(props) {
  const fileInput = useRef();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadProofUrl, setUploadProofUrl] = useState(
    props.data.company_upload_proof || ""
  );

  const swarlPopup = (text, error) => {
    Swal.fire({
      title: error ? "error" : "Done",
      text: text,
      icon: error ? "error" : "success",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.isConfirmed || result.isDismissed) {
        props.onSubmission();
      }
    });
  };

  const onFileUpload = () => {
    const file = fileInput.current.files[0];
    if (!file) {
      setErrorMsg("Select file");
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type;

    if (!fileType.includes("image") && !fileType.includes("pdf")) {
      setErrorMsg("File Type must be image or pdf");
      return;
    }
    if (fileSize > 2.5) {
      setErrorMsg("File Size must be under 2.5 MB");
      return;
    }

    const url = window.URL.createObjectURL(file);
    setUploadProofUrl(url);
  };

  const submission = (e) => {
    e.preventDefault();
    const file = fileInput.current.files[0];
    if (!file) {
      setErrorMsg("Select file");
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.type;

    if (!fileType.includes("image") && !fileType.includes("pdf")) {
      setErrorMsg("File Type must be image or pdf");
      return;
    }
    if (fileSize > 2.5) {
      setErrorMsg("File Size must be under 2.5 MB");
      return;
    }

    const formData = new FormData();
    formData.append("doc_file", file);

    setErrorMsg("");
    setSubmitButtonDisabled(true);
    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/company/upload-company-verification-doc/`,
      {
        method: "POST",
        headers: {
          authorization: `Token ${props.token}`,
        },
        body: formData,
      }
    )
      .then(async (res) => {
        setSubmitButtonDisabled(false);
        const data = await res.json();
        if (!data.status) {
          setErrorMsg(data.message);
          return;
        }
        swarlPopup(data.message);
      })
      .catch(() => {
        setSubmitButtonDisabled(false);
        setErrorMsg("Error connecting server");
      });
  };

  return (
    <form onSubmit={(e) => submission(e)}>
      <div className="form-elem">
        <label>Company Proof</label>
        <input
          ref={fileInput}
          onChange={onFileUpload}
          type="file"
          accept=".png,.jpeg,.jpg,.pdf"
        />
      </div>
      {uploadProofUrl ? (
        <>
          <br />
          <a href={uploadProofUrl} rel="noreferrer" target="_blank">
            Open selected proof
          </a>
        </>
      ) : (
        ""
      )}
      <small className="field-error-msg">{errorMsg}</small>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          onClick={props.onBackPress}
          style={{ margin: "0", cursor: "pointer" }}
        >
          <BackIcon />
          Back
        </p>

        <Button
          disabled={submitButtonDisabled}
          type="submit"
          style={{ borderRadius: "5px", marginRight: "0px" }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(CompanyProof);
