import React, { useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import * as actionTypes from "../../store/action";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import CancelIcon from "@material-ui/icons/Cancel";

import CompanyAddress from "./CompanyAddress/CompanyAddress";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import CompanyProof from "./CompanyProof/CompanyProof";
import DataSubmitted from "./DataSubmitted/DataSubmitted";
import "./VerifyDoc.css";

function VerifyDoc(props) {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [Loading, SetLoading] = useState(false);
  const [CurrentForm, SetCurrentForm] = useState(
    props.dataSubmitted === true ? "dataSubmitted" : "companyInfo"
  );
  const [InfoError, SetInfoError] = useState("");
  const [AddressError, SetAddressError] = useState("");
  const [InfoInitial, SetInfoInitial] = useState({
    PAN: props.data?.company_pan ? props.data.company_pan : "",
    GST: props.data?.company_gst ? props.data.company_gst : "",
    CIN: props.data?.company_cin ? props.data.company_cin : "",
  });
  const [AddressInitial, SetAddressInitial] = useState({
    AddressLine1: props.data?.registered_address?.title
      ? props.data.registered_address.title
      : "",
    AddressLine2: props.data?.registered_address?.address_line_1
      ? props.data.registered_address.address_line_1
      : "",
    AddressLine3: props.data?.registered_address?.address_line_2
      ? props.data.registered_address.address_line_2
      : "",
    Landmark: props.data?.registered_address?.land_mark
      ? props.data.registered_address.land_mark
      : "",
    City: props.data?.registered_address?.city
      ? props.data.registered_address.city
      : "",
    State: props.data?.registered_address?.state
      ? props.data.registered_address.state
      : "",
    Country: props.data?.registered_address?.country
      ? props.data.registered_address.country
      : "",
    PIN: props.data?.registered_address?.pin
      ? props.data.registered_address.pin
      : "",
  });

  const GetCurrentForm = () => {
    switch (CurrentForm) {
      case "companyInfo": {
        return (
          <CompanyInfo
            onSubmission={CompanyInfoSubmission}
            errorMsg={InfoError}
            InitialValues={InfoInitial}
            Loading={Loading}
          />
        );
      }
      case "companyAddress": {
        return (
          <CompanyAddress
            onBackPress={(values) => {
              SetCurrentForm("companyInfo");
              if (values) {
                SetAddressInitial(values);
              }
            }}
            InitialValues={AddressInitial}
            onSubmission={CompnayAddressSubmission}
            Loading={Loading}
            errorMsg={AddressError}
          />
        );
      }
      case "companyProof": {
        return (
          <CompanyProof
            data={props.data}
            onBackPress={() => SetCurrentForm("companyAddress")}
            onSubmission={() => SetCurrentForm("dataSubmitted")}
          />
        );
      }
      case "dataSubmitted": {
        return (
          <DataSubmitted
            data={props.data}
            changeForm={() => SetCurrentForm("companyInfo")}
          />
        );
      }

      default:
        return null;
    }
  };

  const CompanyInfoSubmission = (values) => {
    SetInfoInitial(values);
    if (!values.PAN || !values.GST || !values.CIN) {
      SetInfoError("All Fields are required");
      return;
    }

    SetInfoError("");
    SetLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/company/add-or-update-verification-data/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Token ${props.token}`,
        },
        body: JSON.stringify({
          company_pan: values.PAN,
          company_gst: values.GST,
          company_cin: values.CIN,
        }),
      }
    )
      .then(async (res) => {
        SetLoading(false);
        const data = await res.json();
        if (data.status) {
          SetCurrentForm("companyAddress");
        } else {
          SetInfoError(data.message);
        }
      })
      .catch((err) => {
        SetInfoError("Error contacting server");
        SetLoading(false);
      });
  };

  const CompnayAddressSubmission = (values) => {
    SetAddressInitial(values);
    if (
      !values.AddressLine1 ||
      !values.PIN ||
      !values.City ||
      !values.State ||
      !values.Country
    ) {
      SetAddressError("All Fields are required");
      return;
    }

    SetAddressError("");
    SetLoading(true);

    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/company-address/add-or-update-verification-address/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Token ${props.token}`,
        },
        body: JSON.stringify({
          title: values.AddressLine1,
          address_line_1: values.AddressLine2,
          address_line_2: values.AddressLine3,
          land_mark: values.Landmark,
          city: values.City,
          pin: values.PIN,
          state: values.State,
          country: values.Country,
        }),
      }
    )
      .then(async (res) => {
        SetLoading(false);
        const data = await res.json();
        if (data.status) {
          SetCurrentForm("companyProof");
        } else {
          SetAddressError(data.message);
        }
      })
      .catch((err) => {
        SetLoading(false);
        SetAddressError("Error contacting server");
      });
  };

  return ReactDOM.createPortal(
    <div className="verify-doc">
      <Modal
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
        style={styles.Modal}
      >
        <div style={styles.InnerModal}>
          <div style={{ textAlign: "end" }}>
            <CancelIcon
              onClick={() => setHelpModalOpen(false)}
              style={styles.CancelIcon}
            />
          </div>
          <h6 style={styles.Email}>
            Email : <p style={{ margin: "0 5px" }}> contact@ninjasalary.com</p>
          </h6>
          <h6 style={styles.Phone}>
            Phone : <p style={{ margin: "0 5px" }}> +918250066511</p>
          </h6>
        </div>
      </Modal>

      <div
        style={{
          minWidth: props.mobileView ? "250px" : "450px",
        }}
        className="verify-doc_inner"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={styles.SupportIcon} onClick={() => setHelpModalOpen(true)}>
            <ContactSupportIcon
              style={{ color: "darkslategray", fontSize: "1.9rem" }}
            />
          </p>
          <p onClick={props.logoutAction} style={styles.LogoutIcon}>
            Logout
            <ExitIcon style={{ color: "#ff4500" }} />
          </p>
        </div>
        <h2>Business Verification</h2>
        {GetCurrentForm()}
      </div>
    </div>,
    document.querySelector(".app-backdrops")
  );
}

const mapStateToProps = (state) => {
  return {
    mobileView: state.mobileView,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDoc);

const styles = {
  Modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  InnerModal: {
    padding: "20px",
    background: "#fff",
    width: "fit-content",
    height: "fit-content",
    outline: "none",
    boxShadow: "1px 0 12px rgba(0 0 0/20%)",
  },
  Email: {
    display: "flex",
    alignItems: "center",
    margin: "15px 2px",
  },
  Phone: {
    display: "flex",
    alignItems: "center",
    margin: "15px 2px",
  },
  SupportIcon: {
    cursor: "pointer",
    width: "fit-content",
    margin: "4px",
  },
  LogoutIcon: {
    cursor: "pointer",
    width: "fit-content",
    margin: "4px",
  },
  CancelIcon: {
    color: "darkgray",
    cursor: "pointer",
  },
};
