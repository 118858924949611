import React from "react";
// import AddIcon from "@material-ui/icons/Add";

import "./Head.css";

function Head(props) {
  return (
    <div className="directory_head_container">
      <div className="directory_head">
        <div className="directory_head_left">
          <h1>Directory</h1>
          <p>
            No of Employees : <span>{props.totalEmployees || "_"}</span>
          </p>
        </div>
        <div className="directory_head_right">
          {/* <AddIcon
            style={{
              background: "var(--primary-color)",
              borderRadius: "50%",
              color: "#fff",
              fontSize: "2.2rem",
            }}
          />
          Add Employee */}
        </div>
      </div>
    </div>
  );
}

export default Head;
