import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Navbar from "../Header/Navbar/Navbar";
// import Head from "./Head/Head";
import Table from "./Table/Table";
import * as actionTypes from "../../store/action";
import "./TransactionHistory.css";

function TransactionHistory(props) {
  const [allMoneyToPay, setAllMoneyToPay] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  const getTransactionHistoryData = (month, year) => {
    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/user-disbursement/admin-disbursement-history/`,
      {
        method: "POST",
        headers: {
          authorization: `Token ${props.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          month: Number.parseInt(month),
          year: Number.parseInt(year),
        }),
      }
    )
      .then(async (res) => {
        if (res.status === 401) {
          props.logoutAction();
          return;
        }
        const response = await res.json();
        if (response.status) {
          const data = response.data;
          setAllMoneyToPay(
            Number.parseFloat(data.all_money_to_pay_in_paisa) / 100
          );
          setTableData(data.all_transaction_data);
          setTableDataLoaded(true);
        }
      })
      .catch((err) => {
        console.log(
          "Can't connect to server for Transaction History details.",
          err
        );
      });
  };

  const refreshAll = (month, year) => {
    setTableDataLoaded(false);
    getTransactionHistoryData(month, year);
  };

  useEffect(() => {
    if (props.token) {
      getTransactionHistoryData(
        new Date().getMonth() + 1,
        new Date().getFullYear()
      );
    }
  }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return props.auth ? (
    <div className="transaction">
      <Navbar
        logout={props.logoutAction}
        name={props.firstName}
        companyName={props.companyName}
      />
      {/* <Head /> */}

      <Table
        refreshAll={refreshAll}
        tableData={tableData}
        tableDataLoaded={tableDataLoaded}
        totalMoney={allMoneyToPay}
      />
    </div>
  ) : (
    <Redirect to="/signin" />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    token: state.token,
    firstName: state.firstName,
    companyName: state.companyName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
