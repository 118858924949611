import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as actionTypes from "../../store/action";
import Head from "./Head/Head";
import Table from "./Table/Table";
import Navbar from "../Header/Navbar/Navbar";
import "./Directory.css";

function Directory(props) {
  const [totalEmployees, setTotalEmployees] = useState();
  const [onboardedEmployees, setOnboardedEmployees] = useState();
  const [tableData, setTableData] = useState();
  const [showRollOut, setShowRollOut] = useState(false);
  const [dataLoaded, setdataLoaded] = useState(false);

  const getDirectoryData = () => {
    fetch(`${process.env.REACT_APP_SERVER}/ws/v1/company/get-all-user/`, {
      headers: {
        authorization: `Token ${props.token}`,
      },
    })
      .then(async (res) => {
        if (res.status === 401) {
          props.logoutAction();
          return;
        }
        const response = await res.json();
        if (!response.status) return;
        const data = response.data;
        setTotalEmployees(data.all_added_user_count);
        setOnboardedEmployees(data.all_self_onboarded_user_count);
        setTableData(data.all_user_list);
        setShowRollOut(data.show_roll_out);
        setdataLoaded(true);
      })
      .catch((err) => {
        console.log("Can't connect to server for Directory details.", err);
      });
  };

  useEffect(() => {
    if (props.token) {
      getDirectoryData();
    }
  }, [props.token]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.auth) props.history.push("/");
  return (
    <div className="directory">
      <Navbar
        logout={props.logoutAction}
        name={props.firstName}
        companyName={props.companyName}
      />
      <Head totalEmployees={totalEmployees || 0} />
      <Table
        totalEmployees={totalEmployees}
        onboardedEmployees={onboardedEmployees}
        tableData={tableData || []}
        showRollOut={showRollOut}
        refreshAll={getDirectoryData}
        dataLoaded={dataLoaded}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firstName: state.firstName,
    lastName: state.lastName,
    companyName: state.companyName,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Directory);
