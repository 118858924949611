import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import jwt from "jsonwebtoken";

import * as actionTypes from "../../../store/action";
import Button from "../../Button/Button";
import messages from "../../../config/Messages";
import PasswordInput from "./../../Inputs/PasswordInput";
import secretKey from "../../../secret";
import SignInFormRules from "../../../rules/SignInFormRules";
import TextInput from "../../Inputs/TextInput";

const InitialValues = SignInFormRules.InitialValues;
const ValidationSchema = SignInFormRules.SignInSchema();

function SigninForm(props) {
  const errorMessage = useRef();
  const [Loading, SetLoading] = useState();

  const onSubmission = async (values) => {
    try {
      SetLoading(true);
      let body = {
        username: values.Email,
        password: values.Password,
        login_type: "password",
        login_user_type: "admin",
      };

      errorMessage.current.innerText = "";
      const response = await fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/accounts/api-token-auth/`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();
      SetLoading(false);

      if (data.status) {
        const jwtTokem = jwt.sign(
          {
            first_name: data.first_name,
            last_name: data.last_name,
            company_name: data.company_name,
            mobile: data.mobile,
            token: data.token,
          },
          secretKey,
          {
            expiresIn: "6d",
          }
        );
        localStorage.setItem("ns-token", JSON.stringify(jwtTokem));
        // props.loginAction(
        //   data.first_name,
        //   data.last_name,
        //   data.company_name,
        //   data.mobile,
        //   data.token
        // );
        window.location.href = "/";
        // props.history.push("/dashboard");
      } else {
        let msg = data.message;
        errorMessage.current.innerText = msg;
      }
    } catch (error) {
      SetLoading(false);
      if (errorMessage.current) {
        errorMessage.current.innerText = messages.ServerError;
      }
    }
  };

  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={(values) => onSubmission(values)}
      validationSchema={ValidationSchema}
    >
      {({
        handleChange,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        values,
        isValid,
        dirty,
      }) => (
        <>
          <form
            autoComplete="on"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              label="Email"
              type="email"
              autoComplete="on"
              name="email"
              id="email"
              placeholder="abc@xyz.com"
              onChange={handleChange("Email")}
              error={touched.Email ? errors.Email : ""}
              onBlur={(e) => setFieldTouched("Email")}
            />

            <PasswordInput
              error={touched.Password ? errors.Password : ""}
              onChange={handleChange("Password")}
              onBlur={(e) => setFieldTouched("Password")}
            />

            <p
              style={styles.ForgotBTN}
              onClick={() => props.changeForm("forgotPassword", values.Email)}
            >
              Forgot Password?
            </p>

            <Button
              raised={isValid && dirty}
              disabled={!(isValid && dirty) || Loading === true}
              type="submit"
            >
              Sign in
            </Button>
            <small style={styles.GlobalError} ref={errorMessage}></small>
          </form>
        </>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (fName, lName, cName, mobile, token) =>
      dispatch({
        type: actionTypes.LOGIN,
        firstName: fName,
        lastName: lName,
        companyName: cName,
        mobile: mobile,
        token: token,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);

const styles = {
  ForgotBTN: {
    width: "fit-content",
    margin: "4px 0",
    marginLeft: "auto",
    fontSize: "0.9rem",
    cursor: "pointer",
  },
  GlobalError: {
    fontSize: "0.9rem",
    color: "#ff4500",
    fontWeight: "bolder",
    display: "block",
    transform: "translateY(-10px)",
    maxWidth: "290px",
    textAlign: "center",
    margin: "auto",
  },
};
