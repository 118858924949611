import React from "react";
import brokenSvg from "../assets/broken.svg";

function BrokenPage() {
  return (
    <div style={{ textAlign: "center", minHeight: "100vh" }}>
      <img style={{ maxWidth: "360px" }} src={brokenSvg} alt="svg not found" />
      <h2
        style={{
          textAlign: "center",
          margin: "30px 0",
        }}
      >
        Something is broken. Please try again
      </h2>
    </div>
  );
}

export default BrokenPage;
