import * as Yup from "yup";

const PAN_CARD_REGEX = /[A-Z]{5}\d{4}[A-Z]{1}/;
const GST_REGEX = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
const CIN_REGEX =
  /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

const InitialValues = {
  PAN: "",
  GST: "",
  CIN: "",
};

const CompnayInfoSchema = () => {
  return Yup.object().shape({
    PAN: Yup.string()
      .required("PAN is required")
      .matches(PAN_CARD_REGEX, {
        message: "Must be a valid PAN Number",
      })
      .max(10)
      .nullable("Must be a valid PAN Number"),
    GST: Yup.string()
      .required("GST is required.")
      .matches(GST_REGEX, {
        message: "Must be a valid GST Number",
      })
      .max(15)
      .nullable("Must be a valid GST Number"),
    CIN: Yup.string()
      .required("CIN is required.")
      .matches(CIN_REGEX, {
        message: "Must be a valid CIN Number",
      })
      .max(21)
      .nullable("Must be a valid CIN Number"),
  });
};

const Exports = {
  CompnayInfoSchema,
  InitialValues,
};

export default Exports;
