import React from "react";

import "./Preloader.css";

function Preloader() {
  return (
    // <div className="preloader_container">
    //   <section>
    //     <div className="preloader" />
    //   </section>
    // </div>
    <div className="preloader_container">
      <div className="preloader"></div>
    </div>
  );
}

export default Preloader;
