import React, { useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import Button from "../../Button/Button";
import "./Sidebar.css";

function SidebarLeft(props) {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onRollout = () => {
    if (props.token) {
      setButtonDisabled(true);
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/roll-out-all-user/`,
        {
          method: "POST",
          headers: {
            authorization: `Token ${props.token}`,
          },
        }
      )
        .then(async (res) => {
          setButtonDisabled(false);
          const data = await res.json();
          if (data.status) {
            swarlPopup(data.message);
          }
        })
        .catch((err) => {
          setButtonDisabled(false);
          console.log("can't react server for Roll out");
        });
    } else {
      console.log("no token");
    }
  };

  const swarlPopup = (text) => {
    Swal.fire({
      title: "Done",
      text: text,
      icon: "success",
      confirmButtonText: "Okay",
    });
  };

  return (
    <div className="dashboard_sidebar-right_container">
      <div className="dashboard_sidebar-right">
        <div className="dashboard_sidebar-right_box">
          <div className="dashboard_sidebar-right_box_item">
            <label>Employees Onboarded </label>
            <p>{props.onboarded || 0}</p>
          </div>
          <div className="dashboard_sidebar-right_box_item">
            <label>Employees Not Activated </label>
            <p>{props.notActivated || 0}</p>
          </div>
          {props.showRollOut ? (
            <Button type="button" disabled={buttonDisabled} onClick={onRollout}>
              Rollout
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(SidebarLeft);
