import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Formik } from "formik";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import Button from "../../Button/Button";
import CompanyAddressRules from "../../../rules/CompanyAddressRules";
import TextInput from "../../Inputs/TextInput";

const ValidationSchema = CompanyAddressRules.CompanyAddressSchema();
const DefaultInitial = CompanyAddressRules.InitialValues;

function CompanyAddress({
  Loading = false,
  errorMsg = "",
  InitialValues = DefaultInitial,
  onSubmission,
  onBackPress,
}) {
  const [Fetching, SetFetching] = useState(false);

  const pinHandler = async (pin) => {
    try {
      SetFetching(true);
      const res = await fetch(`https://api.postalpincode.in/pincode/${pin}`);
      const data = await res.json();

      SetFetching(false);
      if (data[0].Status === "Success") {
        let response = {
          City: data[0].PostOffice[0].District,
          State: data[0].PostOffice[0].State,
          Country: data[0].PostOffice[0].Country,
        };
        return response;
      }
      return null;
    } catch (error) {
      SetFetching(false);
      return null;
    }
  };

  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={onSubmission}
      validationSchema={ValidationSchema}
    >
      {({
        setFieldTouched,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid
              container
              spacing={2}
              style={{ margin: "0", width: "100%", maxWidth: "850px" }}
            >
              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="Address Line 1"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  value={values.AddressLine1}
                  placeholder="Address Line 1"
                  onChange={handleChange("AddressLine1")}
                  mandatory={true}
                  onBlur={(e) => setFieldTouched("AddressLine1")}
                  error={touched.AddressLine1 ? errors.AddressLine1 : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="Address Line 2"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  value={values.AddressLine2}
                  placeholder="Address Line 2"
                  onChange={handleChange("AddressLine2")}
                  onBlur={(e) => setFieldTouched("AddressLine2")}
                  error={touched.AddressLine2 ? errors.AddressLine2 : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="Address Line 3"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  value={values.AddressLine3}
                  placeholder="Address Line 3"
                  onChange={handleChange("AddressLine3")}
                  onBlur={(e) => setFieldTouched("AddressLine3")}
                  error={touched.AddressLine3 ? errors.AddressLine3 : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="Landmark"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  value={values.Landmark}
                  placeholder="Landmark"
                  onChange={handleChange("Landmark")}
                  onBlur={(e) => setFieldTouched("Landmark")}
                  error={touched.Landmark ? errors.Landmark : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="PIN Code"
                  errorSpanClass="field-error-msg"
                  maxLength="6"
                  type="text"
                  value={values.PIN}
                  placeholder="PIN Code"
                  mandatory={true}
                  onChange={async (e) => {
                    const value = e.target.value;
                    const char = value.slice(-1);
                    if ((char >= "0" && char <= "9") || value === "") {
                      setFieldValue("PIN", value);
                    }

                    if (value.length > 5) {
                      const response = await pinHandler(value);
                      if (response !== null) {
                        setFieldValue("City", response.City);
                        setFieldValue("State", response.State);
                        setFieldValue("Country", response.Country);
                      }
                    }
                  }}
                  onBlur={(e) => setFieldTouched("PIN")}
                  error={touched.PIN ? errors.PIN : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="City"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  mandatory={true}
                  value={values.City}
                  placeholder="City"
                  disabled={Fetching}
                  onChange={handleChange("City")}
                  onBlur={(e) => setFieldTouched("City")}
                  error={touched.City ? errors.City : ""}
                  normalText={Fetching ? `Fetching ${"City"}` : null}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="State"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  value={values.State}
                  mandatory={true}
                  placeholder="State"
                  disabled={Fetching}
                  onChange={handleChange("State")}
                  onBlur={(e) => setFieldTouched("State")}
                  normalText={Fetching ? `Fetching ${"State"}` : null}
                  error={touched.State ? errors.State : ""}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextInput
                  topDivClass="form-elem"
                  label="Country"
                  errorSpanClass="field-error-msg"
                  maxLength="30"
                  type="text"
                  mandatory={true}
                  value={values.Country}
                  placeholder="Country"
                  disabled={Fetching}
                  onChange={handleChange("Country")}
                  onBlur={(e) => setFieldTouched("Country")}
                  normalText={Fetching ? `Fetching ${"Country"}` : null}
                  error={touched.Country ? errors.Country : ""}
                />
              </Grid>
            </Grid>
            <small className="field-error-msg">{errorMsg}</small>

            <div style={{ textAlign: "left", marginLeft: 15 }}>
              <span>
                <span class="red-star">*</span> fields are mandatory
              </span>
            </div>

            <div style={styles.BTNS}>
              <p
                onClick={() => onBackPress(values)}
                style={{ margin: "0", cursor: "pointer" }}
              >
                <BackIcon />
                Back
              </p>
              <Button
                disabled={
                  !values.AddressLine1 ||
                  !values.PIN ||
                  !values.City ||
                  !values.State ||
                  !values.Country ||
                  Loading
                }
                type="submit"
                style={{ borderRadius: "5px", marginRight: "0px" }}
              >
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(CompanyAddress);

const styles = {
  BTNS: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
