import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import { Avatar } from "@material-ui/core"; //eslint fix

import logo from "../../../assets/logo/logo.png";
import logoText from "../../../assets/logo/logo-text.png";
import "./Navbar.css";

function Navbar(props) {
  const [boxOpen, setBoxOpen] = useState(false);
  const nav = useRef();
  const navCont = useRef();

  useEffect(() => {
    navCont.current.style.height = `${nav.current.clientHeight}px`;
  }, []);

  return (
    <div ref={navCont} className="navbar_container">
      <div
        onClick={() => setBoxOpen(false)}
        className={`navbar_backdrop ${
          boxOpen ? "navbar_backdrop_active" : " "
        }`}
      />
      <div ref={nav} className="navbar">
        <div className="navbar_inner">
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <div className="navbar_logo">
              <img src={logo} alt="" />
              <img
                style={{ transform: "translateX(-18px)", height: "24px" }}
                src={logoText}
                alt="Ninja Salary"
              />
            </div>
          </Link>
          <div className={`navbar_user`} onClick={() => setBoxOpen(!boxOpen)}>
            {/* <Avatar
              style={{ height: "28px", width: "28px" }}
              src="https://cdn.pixabay.com/photo/2016/08/20/05/38/avatar-1606916__340.png"
            /> */}
            <div className="navbar_user_body">
              <p>
                Hello <span>{props.name || "Username"}</span>
              </p>

              {boxOpen ? (
                <KeyboardArrowUpIcon style={{ fontSize: "1.5rem" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ fontSize: "1.5rem" }} />
              )}
            </div>
            <small>{props.companyName || "Company Name"}</small>
            <div className={`navbar_box ${boxOpen ? "navbar_box_active" : ""}`}>
              <ul>
                <Link
                  to="/profile"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <li>Profile</li>
                </Link>
                <hr />
                {/* <Link
                  to="/directory"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <li>Directory</li>
                </Link>
                <hr />
                <Link
                  to="/transaction-history"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <li>Transaction History</li>
                </Link>
                <hr /> */}
                <li onClick={props.logout}>Logout</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
