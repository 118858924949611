import React from "react";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

function DataSubmitted(props) {
  return (
    <div>
      <CheckIcon style={{ fontSize: "7rem", color: "var(--primary-color)" }} />
      <h5>Your data is submitted. Waiting for verification</h5>
      <p style={{ marginTop: "25px" }}>
        Want to re enter the form?{" "}
        <span
          onClick={() => props.changeForm("companyInfo")}
          style={{ cursor: "pointer", color: "var(--primary-color)" }}
        >
          Click here
        </span>
      </p>
    </div>
  );
}

export default DataSubmitted;
