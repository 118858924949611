import React, { useRef, useState, useEffect } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/DoneAll";

import Button from "../../Button/Button";

function SetPasswordForm(props) {
  const password = useRef();

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgColor, setErrorMsgColor] = useState("");
  const [passwordError, setPasswordError] = useState(" ");
  const [passError, setPassError] = useState({
    length: false,
    special: false,
    capital: false,
    small: false,
    number: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);

  const [countdown, setCountdown] = useState(3);
  const [finalRedirect, setFinalRedirect] = useState(false);

  useEffect(() => {
    if (passwordError) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [passwordError]);

  const onSubmission = (e) => {
    e.preventDefault();

    if (passwordError) return;

    setNextButtonDisabled(true);

    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/accounts/set-employer-forgot-password/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          new_password: password.current.value,
          forgot_password_key: props.passwordKey,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (!data.status) {
          setErrorMsg(data.message);
        } else {
          setErrorMsgColor("green");
          setErrorMsg(data.message);
          setFinalRedirect(true);
          setTimeout(() => {
            props.changeForm("signin");
          }, 3200);

          const timeOut = (count) => {
            setTimeout(() => {
              count -= 1;
              setCountdown(count);
              if (count > -1) timeOut(count);
            }, 1000);
          };

          timeOut(countdown);
        }
      })
      .catch((err) => {
        setNextButtonDisabled(false);
      });
  };

  return (
    <form onSubmit={(e) => onSubmission(e)}>
      <h5 style={{ textAlign: "start" }}>Reset Password</h5>
      <div className="signup_form-elem">
        {/* <label>Set Password</label> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            background: "#fff",
            boxShadow: `${
              passwordFieldClicked
                ? "-2px 7px 12px rgb(0 0 0 / 25%)"
                : "-1px 2px 5px rgb(0 0 0 / 10%)"
            }`,
            position: "relative",
          }}
        >
          <input
            onFocus={() => setPasswordFieldClicked(true)}
            className="password-input"
            style={{ flex: "10" }}
            ref={password}
            type={passwordVisible ? "text" : "password"}
            required
            placeholder="Enter your Password"
            onChange={(e) => {
              const value = e.target.value.trim();
              const numberRegex = /^(?=.*\d)/;
              const lengthRegex = /^.{6,30}$/;
              const specialRegex = /^(?=.*[!@#$%^&*])/;
              const capitalRegex = /^(?=.*[A-Z])/;
              const smallRegex = /^(?=.*[a-z])/;

              const myErr = { ...passError };
              myErr.number = numberRegex.test(value);
              myErr.special = specialRegex.test(value);
              myErr.length = lengthRegex.test(value);
              myErr.capital = capitalRegex.test(value);
              myErr.small = smallRegex.test(value);
              setPassError(myErr);
            }}
            onBlur={(e) => {
              setPasswordFieldClicked(false);
              const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;
              if (e.target.value.trim() === "") {
                setPasswordError("Enter Password");
              } else if (regex.test(e.target.value)) {
                setPasswordError("");
              } else {
                setPasswordError("Invalid Password");
              }
            }}
          />
          {passwordVisible ? (
            <VisibilityIcon
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setPasswordVisible(!passwordVisible);
              }}
            />
          ) : (
            <VisibilityOffIcon
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                setPasswordVisible(!passwordVisible);
              }}
            />
          )}
          <div
            className={`signup_pass-box ${
              passwordFieldClicked ? "signup_pass-box_active" : " "
            }`}
          >
            <li>
              <CheckBoxIcon
                style={{ color: `${passError.capital ? "green" : "gray"}` }}
              />
              <p>Capital letter [A-Z]</p>
            </li>
            <li>
              <CheckBoxIcon
                style={{ color: `${passError.small ? "green" : "gray"}` }}
              />
              <p>Small letter [a-z]</p>
            </li>
            <li>
              <CheckBoxIcon
                style={{ color: `${passError.number ? "green" : "gray"}` }}
              />
              <p>Number [0-9]</p>
            </li>
            <li>
              <CheckBoxIcon
                style={{ color: `${passError.special ? "green" : "gray"}` }}
              />
              <p>Special Character [!@#$%^&*]</p>
            </li>
            <li>
              <CheckBoxIcon
                style={{ color: `${passError.length ? "green" : "gray"}` }}
              />
              <p>Length [6-30]</p>
            </li>
          </div>
        </div>
        <small className="field-error-msg">{passwordError}</small>
      </div>

      <small
        style={{ color: errorMsgColor, margin: "auto" }}
        className="field-error-msg"
      >
        {errorMsg}
        {finalRedirect ? (
          <>
            <DoneIcon
              style={{ fontSize: "3rem", display: "block", margin: "auto" }}
            />
            <p
              style={{
                color: "green",
                margin: "0.7rem auto auto auto",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              Redirecting to Signin in {countdown} sec. If not redirected
              <span
                className="login_special-text"
                style={{ fontSize: "1rem", fontWeight: "500" }}
                onClick={() => props.changeForm("signin")}
              >
                {" "}
                Click here
              </span>
            </p>
          </>
        ) : (
          ""
        )}
      </small>

      <Button
        style={{ borderRadius: "5px" }}
        type="submit"
        disabled={nextButtonDisabled}
      >
        Update Password
      </Button>
    </form>
  );
}

export default SetPasswordForm;
