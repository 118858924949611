function TextInput({
  label,
  ref,
  type = "text",
  placeholder,
  onBlur,
  error,
  onChange,
  pattern,
  maxLength,
  value = null,
  autoComplete = "off",
  name,
  id,
  topDivClass = "signup_form-elem",
  errorSpanClass = "field-error-msg",
  onPaste,
  mandatory = false,
  normalText,
  disabled,
}) {
  return (
    <div className={topDivClass}>
      <label>
        {label}
        {mandatory ? <span class="red-star">*</span> : null}
      </label>
      <input
        ref={ref}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        pattern={pattern}
        maxLength={maxLength}
        autoComplete={autoComplete}
        {...(value !== null ? { value: value } : {})}
        name={name}
        style={{ opacity: disabled ? 0.5 : 1 }}
        readOnly={disabled}
        id={id}
        onPaste={onPaste}
      />
      {error ? (
        <small className={errorSpanClass}>{error}</small>
      ) : normalText ? (
        <small className={errorSpanClass} style={{ color: "green" }}>
          {normalText}
        </small>
      ) : null}
    </div>
  );
}

export default TextInput;
