import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "../../Button/Button";
import "../Profile.css";

function UpdatePassword(props) {
  const password = useRef();
  const oldPassword = useRef();
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [oldPasswordFieldClicked, setOldPasswordFieldClicked] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(" ");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);
  const [passwordError, setPasswordError] = useState(" ");
  const [passError, setPassError] = useState({
    length: false,
    special: false,
    capital: false,
    small: false,
    number: false,
  });

  const submmission = () => {
    if (passwordError || oldPasswordError) return;

    setUpdateButtonDisabled(true);

    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/accounts/update-user-password/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Token ${props.token}`,
        },
        body: JSON.stringify({
          old_password: oldPassword.current.value,
          new_password: password.current.value,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();
        if (data.status) {
          props.onClose();
          swarlPopup(data.message);
        } else {
          setUpdateButtonDisabled(false);
          setErrorMsg(data.message);
        }
      })
      .catch((err) => {
        setUpdateButtonDisabled(false);
      });
  };

  const swarlPopup = (text, error) => {
    Swal.fire({
      title: error ? "error" : "Done",
      text: text,
      icon: "success",
      confirmButtonText: "Okay",
    });
  };

  useEffect(() => {
    if (passwordError || oldPasswordError) {
      setUpdateButtonDisabled(true);
    } else {
      setErrorMsg("");
      setUpdateButtonDisabled(false);
    }
  }, [oldPasswordError, passwordError]);

  return (
    <div
      className="profile_backdrop-box_update-password"
      onClick={(e) => e.stopPropagation()}
    >
      <div style={{ padding: "5px", textAlign: "end" }}>
        <CancelIcon style={{ cursor: "pointer" }} onClick={props.onClose} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          submmission();
          setTimeout(() => {
            e.target.reset();
          }, 450);
        }}
      >
        <h4>Update Password</h4>
        <div
          className="update-password_form-elem"
          style={{ margin: "10px 2px" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              background: "#fff",
              boxShadow: `${
                oldPasswordFieldClicked
                  ? "-2px 7px 12px rgb(0 0 0 / 25%)"
                  : "-1px 2px 5px rgb(0 0 0 / 10%)"
              }`,
              position: "relative",
            }}
          >
            <input
              onFocus={() => setOldPasswordFieldClicked(true)}
              className="password-input"
              style={{ flex: "10" }}
              ref={oldPassword}
              type={oldPasswordVisible ? "text" : "password"}
              required
              placeholder="Enter Old Password"
              onBlur={(e) => {
                setOldPasswordFieldClicked(false);
                if (e.target.value) setOldPasswordError("");
                else setOldPasswordError("Enter Password");
              }}
            />
            {oldPasswordVisible ? (
              <VisibilityIcon
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOldPasswordVisible(!oldPasswordVisible);
                }}
              />
            ) : (
              <VisibilityOffIcon
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOldPasswordVisible(!oldPasswordVisible);
                }}
              />
            )}
          </div>
          <small className="field-error-msg">{oldPasswordError}</small>
        </div>
        <div
          className="update-password_form-elem"
          style={{ margin: "10px 2px" }}
        >
          {/* <label>Set Password</label> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              background: "#fff",
              boxShadow: `${
                passwordFieldClicked
                  ? "-2px 7px 12px rgb(0 0 0 / 25%)"
                  : "-1px 2px 5px rgb(0 0 0 / 10%)"
              }`,
              position: "relative",
            }}
          >
            <input
              onFocus={() => setPasswordFieldClicked(true)}
              className="password-input"
              style={{ flex: "10" }}
              ref={password}
              type={passwordVisible ? "text" : "password"}
              required
              placeholder="Enter new Password"
              onChange={(e) => {
                const value = e.target.value.trim();
                const numberRegex = /^(?=.*\d)/;
                const lengthRegex = /^.{6,30}$/;
                const specialRegex = /^(?=.*[!@#$%^&*])/;
                const capitalRegex = /^(?=.*[A-Z])/;
                const smallRegex = /^(?=.*[a-z])/;

                const myErr = { ...passError };
                myErr.number = numberRegex.test(value);
                myErr.special = specialRegex.test(value);
                myErr.length = lengthRegex.test(value);
                myErr.capital = capitalRegex.test(value);
                myErr.small = smallRegex.test(value);
                setPassError(myErr);
              }}
              onBlur={(e) => {
                setPasswordFieldClicked(false);
                const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,30}$/;
                if (e.target.value.trim() === "") {
                  setPasswordError("Enter Password");
                } else if (regex.test(e.target.value)) {
                  setPasswordError("");
                } else {
                  setPasswordError("Invalid Password");
                }
              }}
            />
            {passwordVisible ? (
              <VisibilityIcon
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPasswordVisible(!passwordVisible);
                }}
              />
            ) : (
              <VisibilityOffIcon
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setPasswordVisible(!passwordVisible);
                }}
              />
            )}
            <div
              className={`update-password_pass-box ${
                passwordFieldClicked ? "update-password_pass-box_active" : " "
              }`}
            >
              <li>
                <CheckBoxIcon
                  style={{
                    color: `${passError.capital ? "green" : "gray"}`,
                  }}
                />
                <p>Capital letter [A-Z]</p>
              </li>
              <li>
                <CheckBoxIcon
                  style={{ color: `${passError.small ? "green" : "gray"}` }}
                />
                <p>Small letter [a-z]</p>
              </li>
              <li>
                <CheckBoxIcon
                  style={{
                    color: `${passError.number ? "green" : "gray"}`,
                  }}
                />
                <p>Number [0-9]</p>
              </li>
              <li>
                <CheckBoxIcon
                  style={{
                    color: `${passError.special ? "green" : "gray"}`,
                  }}
                />
                <p>Special Character [!@#$%^&*]</p>
              </li>
              <li>
                <CheckBoxIcon
                  style={{
                    color: `${passError.length ? "green" : "gray"}`,
                  }}
                />
                <p>Length [6-30]</p>
              </li>
            </div>
          </div>
          <small className="field-error-msg">{passwordError}</small>
        </div>

        <small className="field-error-msg">{errorMsg}</small>

        <Button
          style={{ borderRadius: "10px" }}
          type="submit"
          disabled={updateButtonDisabled}
        >
          Update
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(UpdatePassword);
