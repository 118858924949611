import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "./store/action";
import jwt from "jsonwebtoken";
import secretKey from "./secret";

import Preloader from "./components/Prelaoder/Preloader";
import Auth from "./components/Auth/EmployerAuth";
import Dashboard from "./components/Dashboard/Dashboard";
import Directory from "./components/Directory/Directory";
import Profile from "./components/Profile/Profile";
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";
import Receipt from "./components/Receipt/Receipt";
import VerifyDoc from "./components/VerifyDoc/VerifyDoc";
import BrokenPage from "./components/BrokenPage";
import "./App.css";

let viewTimer;
function App(props) {
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);
  const [allVerified, setAllVerified] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [companyVerifiedData, setCompanyVerifiedData] = useState();

  const changeView = () => {
    clearTimeout(viewTimer);
    viewTimer = setTimeout(() => {
      setMobileView(window.innerWidth < 600);
    }, 500);
  };

  useEffect(() => {
    props.mobileViewAction(mobileView);
  }, [mobileView]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", changeView);
    const jwtToken = JSON.parse(localStorage.getItem("ns-token")) || "";

    jwt.verify(jwtToken, secretKey, (err, jwtData) => {
      if (err) {
        return;
      }
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/company/get-company-verified-data/
      `,
        {
          headers: {
            authorization: `Token ${jwtData.token}`,
          },
        }
      )
        .then(async (res) => {
          if (res.status === 401) {
            props.logoutAction();
            return;
          }
          const response = await res.json();
          if (response.status) {
            const data = response.data;
            setCompanyVerifiedData(data);
            if (data.is_data_submitted) {
              setDataSubmitted(true);
            }
            if (data.is_data_submitted && data.is_company_verified) {
              setAllVerified(true);
            }
          }
        })
        .catch(() => {
          window.location.href = "/broken";
        });
      props.loginAction(
        jwtData.first_name,
        jwtData.last_name,
        jwtData.company_name,
        jwtData.mobile,
        jwtData.token
      );
    });

    setTimeout(props.loadedAction, 1200);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return props.isLoaded ? (
    <Router>
      <div className="App">
        {allVerified ? (
          <Switch>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/directory" exact component={Directory} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/receipt" exact component={Receipt} />
            <Route
              path="/transaction-history"
              exact
              component={TransactionHistory}
            />
            <Route path="/:page" exact component={Auth} />
            {props.auth ? (
              <Route path="/" exact component={Dashboard} />
            ) : (
              <Route path="/" exact component={Auth} />
            )}
          </Switch>
        ) : (
          <div>
            {props.auth ? (
              <>
                <VerifyDoc
                  dataSubmitted={dataSubmitted}
                  data={companyVerifiedData}
                />
                <Route path="/" component={Dashboard} />
              </>
            ) : (
              <Route path="/" component={Auth} />
            )}
          </div>
        )}

        <Route path="/broken" exact component={BrokenPage} />
      </div>
    </Router>
  ) : (
    <Preloader />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isLoaded: state.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mobileViewAction: (view) =>
      dispatch({ type: actionTypes.MOBILE_VIEW, mobileView: view }),
    loadedAction: () => dispatch({ type: actionTypes.LOADED }),
    loginAction: (fName, lName, cName, mobile, token) =>
      dispatch({
        type: actionTypes.LOGIN,
        firstName: fName,
        lastName: lName,
        companyName: cName,
        mobile: mobile,
        token: token,
      }),
    logoutAction: () => dispatch({ type: actionTypes.LOGOUT }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
