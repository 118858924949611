import { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

function PasswordInput({
  label = "Password",
  onFocus = () => {},
  placeholder = "Enter your Password",
  onBlur = () => {},
  error,
  onChange,
  children,
  value = null,
}) {
  const [Visible, SetVisible] = useState(false);
  const [passwordFieldClicked, setPasswordFieldClicked] = useState(false);

  return (
    <div className="login_form-elem">
      <label>{label}</label>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          background: "#fff",
          position: "relative",
          boxShadow: `${
            passwordFieldClicked
              ? "-2px 7px 12px rgb(0 0 0 / 25%)"
              : "-1px 2px 5px rgb(0 0 0 / 10%)"
          }`,
        }}
      >
        <input
          onFocus={() => {
            setPasswordFieldClicked(true);
            onFocus();
          }}
          className="password-input"
          style={{ flex: "10" }}
          type={Visible ? "text" : "password"}
          placeholder={placeholder}
          onBlur={(e) => {
            setPasswordFieldClicked(false);
            onBlur(e);
          }}
          onChange={onChange}
          {...(value !== null ? { value: value } : {})}
        />
        {Visible ? (
          <VisibilityIcon
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              SetVisible(!Visible);
            }}
          />
        ) : (
          <VisibilityOffIcon
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              SetVisible(!Visible);
            }}
          />
        )}
        {children}
      </div>
      <small className="field-error-msg">{error}</small>
    </div>
  );
}

export default PasswordInput;
