import React, { useState } from "react";

import Login from "./EmployerLogin";
import Signup from "./EmployerSignup";
import "./Auth.css";

function Auth(props) {
  const page = props.location.pathname.split("/").reverse()[0];
  const [comp, setComp] = useState(page || "signin");
  const switchComp = (cmp) => {
    switch (cmp) {
      case "signin": {
        setComp("signin");
        break;
      }
      case "signup": {
        setComp("signup");
        break;
      }
      default:
        setComp("signin");
    }
  };

  const getComp = () => {
    switch (comp) {
      case "signin": {
        return <Login switch={switchComp} {...props} />;
      }
      case "signup": {
        return <Signup switch={switchComp} {...props} />;
      }

      default:
        return <Login switch={switchComp} {...props} />;
    }
  };

  return (
    <div className="auth">
      <div className="logo">
        <a href="https://ninjasalary.com" className="logo_container">
          <img
            className="logo_image"
            src="https://ninjasalary.com/img/128.png"
            alt=""
          />
          <img
            className="text_image"
            src="https://ninjasalary.com/img/128text.png"
            alt=""
          />
        </a>
      </div>
      <div className="container mt-80">
        <div className="row">
          <div className="col-lg-6 ilustration">
            <img src="https://ninjasalary.com/img/signup_ilus.svg" alt="" />
          </div>
          <div style={{ padding: "0" }} className="col-lg-6">
            {getComp()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
