import React from "react";

import "./Sidebar.css";

function SidebarRight(props) {
  return (
    <div className="dashboard_sidebar-left_container">
      <div className="dashboard_sidebar-left">
        <div className="dashboard_sidebar-left_box">
          <div className="title">{props.totalTransactions || 0}</div>
          <div className="sub-title">Total Transactions</div>
        </div>
        <div className="dashboard_sidebar-left_box">
          <div className="title">{props.moneyWidthdrawl || 0}</div>
          <div className="sub-title">Total Money Withdrawn</div>
        </div>
        <div className="dashboard_sidebar-left_box">
          <div className="title">{props.noOfEmployees || 0}</div>
          <div className="sub-title">Number of Employees</div>
        </div>

        <br />
      </div>
    </div>
  );
}

export default SidebarRight;
