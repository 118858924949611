import * as Yup from "yup";

const InitialValues = {
  AddressLine1: "",
  AddressLine2: "",
  AddressLine3: "",
  Landmark: "",
  City: "",
  State: "",
  Country: "",
  PIN: "",
};

const CompanyAddressSchema = () => {
  return Yup.object().shape({
    AddressLine1: Yup.string()
      .required("Address is required")
      .nullable("Address is required"),
    AddressLine2: Yup.string().optional(),
    AddressLine3: Yup.string().optional(),
    City: Yup.string()
      .required("City is required")
      .nullable("City is required"),
    State: Yup.string()
      .required("State is required")
      .nullable("State is required"),
    Country: Yup.string()
      .required("Country is required")
      .nullable("Country is required"),
    PIN: Yup.string().required("PIN is required").nullable("PIN is required"),
  });
};

const Exports = {
  CompanyAddressSchema,
  InitialValues,
};

export default Exports;
