import React, { useState, useEffect, useRef } from "react";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import Button from "../../Button/Button";
import "./ForgotPasswordForm.css";

function ForgotPasswordForm(props) {
  const backButton = useRef();

  const [email, setEmail] = useState(props.email || "");
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgColor, setErrorMsgColor] = useState("#ff4500");
  const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Send OTP");
  const [fieldError, setFieldError] = useState({
    email: " ",
    otp: " ",
  });

  useEffect(() => {
    if (fieldError.email) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [fieldError.email]);
  useEffect(() => {
    if (fieldError.otp) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [fieldError.otp]);

  const sendOtp = () => {
    fetch(
      `${process.env.REACT_APP_SERVER}/ws/v1/accounts/send-employer-forgot-password-otp/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          username: email,
        }),
      }
    )
      .then(async (res) => {
        setNextButtonDisabled(false);
        const data = await res.json();
        if (!data.status) {
          const myFieldError = { ...fieldError };
          myFieldError.email = data.message;
          setFieldError(myFieldError);
        } else {
          setErrorMsgColor("green");
          setErrorMsg(data.message);
          setTimeout(() => {
            setErrorMsg("");
            setErrorMsgColor("#ff4500");
          }, 6000);
          setOtpFieldVisible(true);
          setEmailFieldDisabled(true);
          setButtonText("Verify OTP");
        }
      })
      .catch((err) => {
        setNextButtonDisabled(false);
      });
  };

  const submission = (e) => {
    e.preventDefault();
    setNextButtonDisabled(true);

    if (!otpFieldVisible) {
      if (fieldError.email) return;
      sendOtp();
    } else {
      if (!otp) {
        setNextButtonDisabled(true);
        const myFieldError = { ...fieldError };
        myFieldError.otp = "Enter OTP";
        setFieldError(myFieldError);
        return;
      }
      fetch(
        `${process.env.REACT_APP_SERVER}/ws/v1/accounts/verify-employer-forgot-password-otp/`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            otp: otp,
          }),
        }
      )
        .then(async (res) => {
          setNextButtonDisabled(false);
          const data = await res.json();
          if (!data.status) {
            setErrorMsg(data.message);
          } else {
            const forgotPassKey = data.data.forgot_password_key;
            props.changeForm("setPassword", forgotPassKey);
          }
        })
        .catch((err) => {
          setNextButtonDisabled(false);
        });
    }
  };

  useEffect(() => {
    const value = props.email;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regex.test(String(value).toLowerCase())) {
      const myFieldError = { ...fieldError };
      myFieldError.email = "";
      setFieldError(myFieldError);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="forgot-password-form">
      <h5 style={{ textAlign: "start", marginBottom: "1rem" }}>
        Forgot Password
      </h5>
      <form onSubmit={(e) => submission(e)}>
        <label>Email</label>
        <input
          type="text"
          placeholder="abc@gmail.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            const value = e.target.value.trim();
            if (!value) {
              const myFieldError = { ...fieldError };
              myFieldError.email = "Enter Email";
              setFieldError(myFieldError);
              setNextButtonDisabled(true);
              return;
            }

            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regex.test(String(value).toLowerCase())) {
              const myFieldError = { ...fieldError };
              myFieldError.email = "";
              setFieldError(myFieldError);
              setNextButtonDisabled(false);
            } else {
              const myFieldError = { ...fieldError };
              myFieldError.email = "Invalid Email";
              setFieldError(myFieldError);
            }
          }}
          disabled={emailFieldDisabled}
          style={{ background: emailFieldDisabled ? "lightgray" : "#fff" }}
        />
        <small className="field-error-msg">{fieldError.email}</small>
        {otpFieldVisible ? (
          <>
            <br />
            <label>OTP</label>
            <input
              style={{ letterSpacing: "5px" }}
              value={otp}
              onChange={(e) => {
                if (e.target.value.length > 6) return;
                const value = e.target.value;
                const char = value.slice(-1);
                if ((char >= "0" && char <= "9") || value === "") setOtp(value);
              }}
              onBlur={() => {
                if (!otp) {
                  const myFieldError = { ...fieldError };
                  myFieldError.otp = "Enter OTP";
                  setFieldError(myFieldError);
                } else {
                  const myFieldError = { ...fieldError };
                  myFieldError.otp = "";
                  setFieldError(myFieldError);
                }
              }}
              type="text"
              placeholder="OTP"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <small className="field-error-msg">{fieldError.otp}</small>
              <p
                style={{
                  width: "fit-content",
                  margin: "4px 0",
                  marginLeft: "auto",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onClick={sendOtp}
              >
                Resend OTP
              </p>
            </div>
          </>
        ) : (
          ""
        )}

        <br />
        <small
          style={{ color: errorMsgColor, margin: "0 auto" }}
          className="field-error-msg"
        >
          {errorMsg}
        </small>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            onClick={() => props.changeForm("signin")}
            style={{ margin: "0", cursor: "pointer" }}
            ref={backButton}
          >
            <BackIcon />
            Back
          </p>
          <Button
            style={{ borderRadius: "5px", margin: "10px 0" }}
            type="submit"
            disabled={nextButtonDisabled}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;
