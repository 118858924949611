import React, { useState } from "react";

import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import SignupForm from "./form/SignupForm";
import SignedupForm from "./form/SignedupForm";
import "./Employer.css";

function EmployerSignup(props) {
  const [form, setForm] = useState(
    <SignupForm onSignup={(msg) => setForm(<SignedupForm message={msg} />)} />
  );

  return props.auth ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="e-signup_container">
      <div className="e-signup">
        <h1 className="signup_head">Sign Up</h1>
        <h3 className="signup_sub-head">Join NinjaSalary</h3>
        {form}
        <p className="signup_footer-text">
          Already a member? &nbsp;
          <span
            onClick={() => {
              props.switch("signin");
              props.history.push("/signin");
            }}
            className="signup_special-text"
          >
            Sign in
          </span>
        </p>
        <br />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(EmployerSignup);
